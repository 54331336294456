//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*eslint-enable */
import { Profile } from '@/services/api.service';

/*eslint-disable */
const ALENDAR_URL = ENV.ALENDAR_URL || '';

export default {
  name: 'Alendar',
  data() {
    return {
      alendarUrl: null,
    };
  },
  components: {},
  methods: {
    iframeFocus() {},
  },
  computed: {},
  mounted() {
    Profile.alendar().then((res) => {
      let birthDate, name, lang;
      const selDate = '';
      if (res && res.data && res.data.data && res.data.data.getSelf) {
        birthDate = res.data.data.getSelf.birthday;
        name = res.data.data.getSelf.fullName;
        lang = res.data.data.getSelf.language;
      }
      this.alendarUrl = ALENDAR_URL.replace('%BIRTH_DATE', birthDate)
        .replace('%SEL_DATE', selDate)
        .replace('%USER_NAME', name)
        .replace('%USER_LANGUAGE', lang);
      setTimeout(this.iframeFocus, 1500);
    });
  },
};
